<script setup lang="ts">
interface LoginData {
  username: string
  password: string
  remember: boolean
}
import { ClientApiError } from '@shopware-pwa/types'
import { getRegisterPermalink, getPasswordRecoveryPermalink } from '~/lib'

const emits = defineEmits<{
  (e: 'success'): void
  (e: 'close'): void
}>()

const { isLoggedIn, login } = useUser()
const { refreshSessionContext } = useSessionContext()
const { pushError } = useNotifications()
const localePath = useLocalePath()

const invokeLogin = async (data: LoginData): Promise<void> => {
  try {
    data.remember = true
    await refreshSessionContext()
    await login(data)
    emits('success')
    emits('close')
  } catch (error) {
    const e = error as ClientApiError
    e.messages.forEach(({ detail }) => pushError(detail))
  }
}
</script>
<template>
  <div v-if="!isLoggedIn">
    <ShopNotifications />
    <FormKit type="form" :actions="false" :incomplete-message="false" @submit="invokeLogin">
      <div class="wrapper">
        <Email :required="true" name="username" :label="$t('shop.loginPage.loginForm.emailPlaceholder')" />
        <Password :required="true" name="password" :label="$t('shop.loginPage.loginForm.passwordPlaceholder')" />
        <Button primary slide type="submit">{{ $t('shop.loginPage.loginForm.submit') }}</Button>
      </div>
    </FormKit>
    <div class="links" @click="$emit('close')">
      <NuxtLink :to="localePath(getPasswordRecoveryPermalink())">{{ $t('shop.loginPage.forgotPassword') }}</NuxtLink>
      <h4>{{ $t('shop.loginPage.registerTitle') }}</h4>
      <NuxtLink :to="localePath(getRegisterPermalink())">{{ $t('shop.loginPage.register') }}</NuxtLink>
    </div>
  </div>
</template>
<style scoped lang="scss">
.wrapper {
  display: grid;
  gap: var(--form-spacing);
  margin-bottom: var(--form-spacing);
}
.links {
  text-align: center;
  margin-bottom: var(--p-mb);
  a {
    color: var(--c-primary);
    text-decoration: underline;
  }
  h4 {
    margin: 20px 0 0 0;
    color: var(--dark);
  }
}
</style>
